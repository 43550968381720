import React, { useEffect, useState } from 'react'
import WAVES from 'vanta/dist/vanta.net.min'
import MainPage from '../mainPage/MainPage'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.vantaRef = React.createRef()
  }

  componentDidMount() {
    this.vantaEffect = WAVES({
      el: this.vantaRef.current,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0xffffff,
      backgroundColor: 0x7bff,
      points: 9.00,
      maxDistance: 24.00,
      spacing: 20.00
    })
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy()
  }

  render() {
    return (
    <div ref={this.vantaRef}>
      <MainPage reRenderVanta={() => {if (document.documentElement.scrollHeight != this.vantaEffect.height) {
          this.vantaEffect.resize()}
        }} />
    </div>
    )
  }
}
export default App;